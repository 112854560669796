export const SIDEBAR_WIDTH = 290;
export const ADMIN_SIDEBAR_WIDTH = 260;
export const ADMIN_SIDEBAR_COLLAPSED_WIDTH = 72;

const applicationsStyles = {
  'uptime-controller': {
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: SIDEBAR_WIDTH,
        },
      },
    },
  },
  'uptime-admin': {},
  'uptime-market': {},
  'uptime-shared': {},
  'uptime-portal': {},
};

export default applicationsStyles;
