import React, { Fragment, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WrapLink from '@uptime/shared/components/WrapLink';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import usePermissions from 'shared/hooks/usePermissions';
import { UIContext } from 'providers/UIProvider';

import useExploDashboards from '../hooks/useExploDashboards';

import { useStyles } from '../LeftNav.styles';

const ROOT_ROUTES = ['/app', '/app/site/dashboard', '/app/dashboard', '/app/dashboard/compliance'];
const DEVICE_DASHBOARD = 'Devices';
const FINANCIALS_DASHBOARD = 'Financials';
const ACTIVITIES_DASHBOARD = 'Activities';
const SERVICE_REQUESTS_DASHBOARD = 'Service Requests';
const WARRANTY_DASHBOARD = 'Warranty & Contracts';

const isIncludes = (pathname = '') =>
  ROOT_ROUTES.includes(pathname) ||
  pathname.startsWith('/app/dashboard/compliance') ||
  pathname.startsWith('/app/dashboard/analytics');

const DashboardMenu = ({ clicked, makeUrl }) => {
  const classes = useStyles();
  const { isOpenSubMenu, onClickMenuItem } = useContext(UIContext);
  const { pathname } = useLocation();
  const {
    runTasksPermission,
    logsManagementPermission,
    deviceDashboardPermission,
    financialsDashboardPermission,
    activitiesDashboardPermission,
    serviceRequestsDashboardPermission,
    warrantyAndContractsDashboardPermission,
    hasAnyExploDashboardPermission,
  } = usePermissions();

  const dashboardItem = {
    url: 'dashboard',
    title: 'Overview',
  };

  const cls = classNames({
    'Mui-selected': isIncludes(pathname),
  });

  const onToggleDashboard = () => onClickMenuItem(dashboardItem.url);

  const exploDashboardIDs = useExploDashboards() || [];
  const filteredDashboards = exploDashboardIDs.filter((el) => {
    return (
      (DEVICE_DASHBOARD === el.title && deviceDashboardPermission) ||
      (FINANCIALS_DASHBOARD === el.title && financialsDashboardPermission) ||
      (ACTIVITIES_DASHBOARD === el.title && activitiesDashboardPermission) ||
      (SERVICE_REQUESTS_DASHBOARD === el.title && serviceRequestsDashboardPermission) ||
      (WARRANTY_DASHBOARD === el.title && warrantyAndContractsDashboardPermission)
    );
  });
  const isOpen = isOpenSubMenu === dashboardItem.url;

  return (
    (runTasksPermission || logsManagementPermission || hasAnyExploDashboardPermission) && (
      <Fragment>
        <ListItem className={cls} variant="parent-item" button onClick={onToggleDashboard}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboards" />
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <ListItem
            component={WrapLink}
            to={makeUrl('dashboard')}
            className={classes.nested}
            activeClassName="Mui-selected"
            onClick={clicked}
            button
            exact
          >
            <ListItemText variant="sub-item-text" primary={dashboardItem.title} />
          </ListItem> */}
            {(runTasksPermission || logsManagementPermission) && (
              <ListItem
                component={WrapLink}
                to={makeUrl('dashboard/compliance')}
                className={classes.nested}
                activeClassName="Mui-selected"
                onClick={clicked}
                button
                exact
              >
                <ListItemText variant="sub-item-text" primary="Compliance" />
              </ListItem>
            )}
            {filteredDashboards.map((el) => (
              <ListItem
                key={el.id}
                component={WrapLink}
                to={`/app/dashboard/analytics/${el.id}`}
                className={classes.nested}
                activeClassName="Mui-selected"
                onClick={clicked}
                button
              >
                <ListItemText variant="sub-item-text" primary={el.title} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </Fragment>
    )
  );
};

DashboardMenu.propTypes = {
  clicked: PropTypes.func.isRequired,
  makeUrl: PropTypes.func.isRequired,
};

export default DashboardMenu;
