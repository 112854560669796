import xlsx from 'xlsx';
import format from 'date-fns/format';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import pick from 'lodash/pick';
import flow from 'lodash/flow';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import { parseSettings } from './users';

import {
  COLOR_DETERMINANT,
  QUICK_START_SETTINGS,
  RGB_REGEXP,
  RGBA_REGEXP,
  RUN_TASK_PERSISTED_FILTER,
  JWT_TOKEN_STORAGE_KEY,
  UNIFIED_IMAGING_REFRESH_TOKEN_TIME,
  COGNITO_KEYS,
  FACILITY_ROLE,
  REPAIRER_ROLE,
} from '@uptime/shared/constants';
import { CREATE_STATE_NAME } from '@uptime/controller/src/components/ServiceProviders/constants';
import _ from 'lodash';
import { WO_REMOVED_STORAGE_KEY } from '../constants/wo';
import { alphabeticalSorting } from './serviceProviders';
import theme from '../theme/muiTheme';

export const checkIsActive = (location, pathNames = []) =>
  pathNames.indexOf(location.pathname.replace('/app/', '')) > -1;

export const parseJwt = (token) => {
  if (!token) {
    return { userId: null, role: null };
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(atob(base64));
};

export const getAuthenticationHeader = () => {
  const jwtTokenStorage = localStorage.getItem(JWT_TOKEN_STORAGE_KEY);

  if (jwtTokenStorage) {
    const accessTokenStorage = JSON.parse(jwtTokenStorage);
    return { authorization: `Bearer ${accessTokenStorage.token}` };
  }

  const cognitoToken = localStorage.getItem(COGNITO_KEYS.ID_TOKEN);

  if (cognitoToken) {
    return { 'c-auth-token': cognitoToken };
  }

  return null;
};

export const getImageUrl = (image) => {
  if (isEmpty(image)) return null;

  const imageTitle = image === 'no-avatar.png' ? null : image;
  // TODO:C - process should be replaced with import.meta after migration admin to VITE
  return imageTitle && `${process.env.REACT_APP_DOMAIN || process.env.VITE_DOMAIN}uploads/${imageTitle}`;
};

export const getUserAvatarName = (src, user) => {
  if (isEmpty(user) || (isEmpty(user.profile) && !user.email)) return 'NA';

  if (src) return null;

  const fieldToGet = user.assignedUser ? 'assignedUser' : 'profile';

  if (user[fieldToGet]) {
    const firstName = user[fieldToGet].firstName.trim();
    const lastName = user[fieldToGet].lastName.trim();

    return `${firstName[0]}${lastName[0]}`;
  }

  return user.email[0];
};

export const getName = (profile) => {
  if (isEmpty(profile)) return '';

  return !profile.firstName || !profile.lastName
    ? ''
    : `${profile.firstName?.trim() || ''} ${profile.lastName?.trim() || ''}`;
};

export const getUserData = (profile = {}, name = undefined) => {
  const userFullName = name || getName(profile);
  const userEmail = profile?.baseUser?.email ?? profile.businessEmail;

  return {
    userFullName,
    userEmail,
    businessName: profile.businessName,
    businessPhone: profile.businessPhone,
  };
};

export const transformTaskData = (data, devices = [], subAccounts) => {
  if (!data) return {};

  return data.map((task) => {
    const device = devices.find((item) => item.id === task.deviceId);
    const user = subAccounts.find(
      (subAccount) => subAccount.profile && subAccount.profile.userId === task.assignedUserId
    );

    return Object.assign({}, task, { device, user });
  });
};

export const parseMilliseconds = (time) => time * 1000;

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const arrayToOptions = (array) =>
  array &&
  array
    .map((item) => ({
      label: item,
      value: item,
    }))
    .sort(alphabeticalSorting('label'));

export const getIsLoading = (props, propNames = []) => {
  const filteredProps = pick(props, ['data', ...propNames]);

  return Object.entries(filteredProps).some((item) => item[1] && item[1].loading);
};

export const clearExternalState = () => {
  localStorage.removeItem('source');
  localStorage.removeItem(CREATE_STATE_NAME);
  localStorage.removeItem(RUN_TASK_PERSISTED_FILTER);
  localStorage.removeItem(WO_REMOVED_STORAGE_KEY);
};

export const clearJwtState = () => {
  localStorage.removeItem(JWT_TOKEN_STORAGE_KEY);
};

export const clearAuthData = () => {
  clearExternalState();
  clearJwtState();
};

export const isPassedQuickStart = (value) => {
  // should be NON STRICT equation, because we revice numbers and sent string_numbers
  return (
    value == QUICK_START_SETTINGS.INFORMED ||
    value == QUICK_START_SETTINGS.DONE ||
    value == QUICK_START_SETTINGS.SKIPPED
  );
};

export const saveToLocalStorage = (state, stateName) => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem(stateName, serializedState);
};

export const loadFromLocalStorage = (stateName) => {
  try {
    const serializedState = localStorage.getItem(stateName);
    if (serializedState === null) return {};
    return JSON.parse(serializedState);
  } catch {
    return {};
  }
};

export const clearStateInLocalStorage = (stateName) => {
  localStorage.removeItem(stateName);
};

export const getFilterParams = (location) => {
  try {
    return JSON.parse(decodeURIComponent(location.search.replace('?', '')));
  } catch {
    return {};
  }
};

export const normalizeSettings = (object) => {
  if (!object) return {};

  const sortedList = Object.entries(object).sort((a, b) => a[1].position - b[1].position);

  return sortedList.reduce(
    (accumulator, [key, value], index) => ({
      ...accumulator,
      [key]:
        key === '__typename'
          ? value
          : {
              ...value,
              position: index,
            },
    }),
    {}
  );
};

export const getParamsFromSearch = (search) => {
  try {
    return JSON.parse(decodeURIComponent(search.replace('?', '')));
  } catch {
    return {};
  }
};

export const getNormalizedHex = (color = theme.palette.grey.black) =>
  color.slice(1).replace(color.length < 5 && /./g, '$&$&');

export const getRGBNumbers = (color = theme.palette.grey.black) => {
  if (color.match(RGB_REGEXP)) {
    const [, red, green, blue] = color.match(RGBA_REGEXP);

    return {
      red: parseInt(red),
      green: parseInt(green),
      blue: parseInt(blue),
    };
  }

  const hex = getNormalizedHex(color);
  const decimalColorNumber = Number(`0x${hex}`);

  return {
    red: decimalColorNumber >> 16,
    green: (decimalColorNumber >> 8) & 255,
    blue: decimalColorNumber & 255,
  };
};

export const getFontHue = (color) => {
  const { red, green, blue } = getRGBNumbers(color);

  const hsp = Math.sqrt(0.299 * (red * red) + 0.587 * (green * green) + 0.114 * (blue * blue));

  return hsp > COLOR_DETERMINANT ? 'light' : 'dark';
};

export const clone = (filter) => ({ ...filter });

export const cloneAndCompose = (...composers) => flow(...composers, clone);

export const createXLSXFile = (data) => {
  const wb = xlsx.utils.book_new();

  data.forEach(({ label, content, headers }) => {
    const ws = xlsx.utils.json_to_sheet([]);

    const heading = headers.map(({ title }) => title);
    xlsx.utils.sheet_add_aoa(ws, [heading]);

    const [firstRawRow = {}, ...rows] = content;
    const row = headers.reduce((obj, { key }) => {
      obj[key] = firstRawRow[key];
      return obj;
    }, {});

    xlsx.utils.sheet_add_json(ws, [row, ...rows], { origin: 'A2', skipHeader: true });

    // tab name length should not contain more than 31 char
    xlsx.utils.book_append_sheet(wb, ws, label.slice(0, 29));
  });

  xlsx.writeFile(wb, `${format(Date.now(), 'LL-dd-yyyy')}.xlsx`);
};

export const getMutatedType = (value) => {
  const numberValue = Number(value);

  return isNaN(value) ? value : numberValue;
};

const isEmptyArray = (list = []) => isArray(list) && isEmpty(list);

export const clearBlankProperties = (obj = {}) =>
  _(obj)
    .omitBy((value) => isUndefined(value) || isNull(value) || isEmptyArray(value))
    .value();

export const trackExpiredSession = (networkError) => {
  if (
    networkError &&
    networkError.name === 'ServerError' &&
    networkError.statusCode === 500 &&
    networkError.message.startsWith('Context creation failed')
  ) {
    window.location.replace('/');
    clearAuthData();
  }
};

export const isNotValid = (value) => value === null || value === undefined;

export const serializeGroups = (groups = []) => {
  if (isNotValid(groups) || groups.length === 0) return null;

  return `;${groups.join(';')};`;
};

export const deserializeGroups = (groups = '') => {
  if (isNotValid(groups)) return [];
  return groups.split(';').filter((item) => item !== '');
};

export const getRuleLabel = (isRequired) => (isRequired ? '*' : '');

export const toEncodeURI = (query) => encodeURIComponent(JSON.stringify(query));

export const getMappedCognitoPayload = () => {
  const idPayload = localStorage.getItem(COGNITO_KEYS.ID_TOKEN_PAYLOAD);

  if (!idPayload)
    return {
      getAccessTokenPayload: async () => null,
      logout: async () => null,
    };

  const payload = JSON.parse(idPayload);

  const tokenPayload = {
    ...payload,
  };

  if (payload.groups && payload.groups.length) {
    tokenPayload.groups = payload.groups.split(',');
  }

  if (payload.roles && payload.roles.length && tokenPayload.type === FACILITY_ROLE) {
    tokenPayload.st_groups = payload.roles.split(',');
  }

  if (payload.features && payload.features.length) {
    tokenPayload.features = payload.features.split(',');
  }

  if (payload.settings) {
    const { partnerId } = parseSettings(payload.settings);
    tokenPayload.partnerId = partnerId ? parseInt(partnerId) : null;
  }

  tokenPayload.userId = Number(payload.userId);
  tokenPayload.accountId = Number(payload.accountId);
  tokenPayload.email = payload.email;
  tokenPayload['user-groups'] = tokenPayload.groups || [];

  return tokenPayload;
};

export const getMappedJwtPayload = (payload) => {
  const tokenPayload = {
    'user-groups': payload['groups']?.split(',')?.filter((item) => item),
    ...payload,
  };

  if (payload.settings) {
    const { partnerId } = parseSettings(payload.settings);
    tokenPayload.partnerId = partnerId ? parseInt(partnerId) : null;
  }

  return tokenPayload;
};

export const isExpiredJwtToken = () => {
  const jwt = localStorage.getItem(JWT_TOKEN_STORAGE_KEY);
  const cognito = localStorage.getItem(COGNITO_KEYS.ID_TOKEN_PAYLOAD);

  if (!jwt && !cognito) return true;

  const token = JSON.parse(cognito || jwt);

  return token.exp < Date.now() / 1000;
};

export const getLegacyToken = () => {
  const jwt = localStorage.getItem(JWT_TOKEN_STORAGE_KEY);
  const isImpersonate = Boolean(jwt);

  if (isImpersonate) {
    const jwtStorage = JSON.parse(jwt);
    return `auth=${jwtStorage.token}`;
  }

  const cognitoIdToken = localStorage.getItem(COGNITO_KEYS.ID_TOKEN);

  return `token=${cognitoIdToken}`;
};

export const responseLens = (data = {}, path = undefined, defaultValue = undefined) => {
  if (!path) return undefined;
  return get(data, path, defaultValue);
};

export const getCountItemsInObject = (objectForCount = {}, ignoreList = []) => {
  const doNotCountIfFalseList = ['isInvalid'];

  return Object.keys(objectForCount).filter((item) => {
    const value = objectForCount[item];
    const conditions = [
      !(isString(value) && value.trim() === ''),
      value !== undefined,
      value !== null,
      !(isObject(value) && isEmpty(value)),
      ignoreList.indexOf(item) < 0,
      doNotCountIfFalseList.includes(item) ? Boolean(value) : true,
    ];

    return conditions.every((item) => item === true);
  }).length;
};

export const getExpirationUIToken = (storage) => {
  const now = Date.now();
  const exp = responseLens(storage, 'exp', now);

  return {
    hasExpired: exp - UNIFIED_IMAGING_REFRESH_TOKEN_TIME < now / 1000,
    expireAt: exp - UNIFIED_IMAGING_REFRESH_TOKEN_TIME,
  };
};

export const trimElementsOfObject = (obj = {}) =>
  Object.keys(obj).reduce(
    (acc, item) => ({
      ...acc,
      [item]: isString(obj[item]) ? obj[item].trim() : obj[item],
    }),
    {}
  );

export const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  return userAgent.indexOf('safari') > -1 && userAgent.indexOf('chrome') === -1;
};

export const printSection = () => {
  if (isSafari()) {
    const newWindow = window.open();

    const styleElements = document.querySelectorAll('style');
    const styleHtmlList = Object.values(styleElements).map((item) => item.outerHTML);
    const html = [...styleHtmlList, document.getElementById('print-section').outerHTML].join('');

    newWindow.document.write(`<div>${html}</div>`);
    newWindow.document.close();

    newWindow.print();
    newWindow.close();
  } else {
    window.print();
  }
};

export const downloadFile = (url, name = 'download') => {
  const aElement = document.createElement('a');
  aElement.setAttribute('href', url);
  aElement.setAttribute('download', name);
  aElement.setAttribute('rel', 'noopener noreferrer');
  document.body.appendChild(aElement);
  aElement.click();
  document.body.removeChild(aElement);
};

const CURRENCY_FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const currencyFormatter = (number = 0) => CURRENCY_FORMATTER.format(number);
