import { useEffect } from 'react';
import isAfter from 'date-fns/isAfter';

import packageJson from '../../../package.json';

const useClearCache = () => {
  useEffect(() => {
    const oneMinute = 60 * 1000;
    const id = setInterval(async () => {
      try {
        const response = await fetch(`/meta.json?q=${Date.now()}`);
        if (response.ok) {
          const meta = await response.json();
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;
          const shouldForceRefresh = isAfter(latestVersionDate, currentVersionDate);

          if (shouldForceRefresh) {
            // this potentially might fix cache issue and 'Error from cloudfront' message
            window.location.replace('/index.html');
          }
        }
      } catch {
        console.log('Failed to get build date.');
      }
    }, oneMinute);

    return () => {
      clearInterval(id);
    };
  }, []);
};

export default useClearCache;
