import { gql } from '@apollo/client';

export const userDocumentList = gql`
  query userDocumentList(
    $filter: UserDocumentFilterInput!
    $sort: [UserDocumentSortInput!]
    $page: Int!
    $itemsAmount: Int = 10
    $search: String
  ) {
    userDocumentList(
      filter: $filter
      sortBy: $sort
      pagination: { page: $page, itemsAmount: $itemsAmount }
      searchBy: $search
    ) {
      hits {
        documentId
        documentTypeCode
        documentVersion
        documentDescription
        documentLink
        documentTitle
        documentExpiration
        documentReminder1
        documentReminder2
        employeeId
        employeeName
        managerId
        managerName
        fileName
        fileMimeType
        documentFileURL
      }
      currentPage
      itemsCount
      pageCount
    }
  }
`;

export const fetchUserDocumentsCount = gql`
  query userDocumentList(
    $filter: UserDocumentFilterInput! = {}
    $sort: [UserDocumentSortInput!]
    $page: Int! = 1
    $itemsAmount: Int = 10
    $search: String
  ) {
    userDocumentList(
      filter: $filter
      sortBy: $sort
      pagination: { page: $page, itemsAmount: $itemsAmount }
      searchBy: $search
    ) {
      itemsCount
    }
  }
`;

export const facilityDocumentList = gql`
  query facilityDocumentList(
    $filter: FacilityDocumentFilterInput!
    $sort: [FacilityDocumentSortInput!]
    $page: Int! = 1
    $itemsAmount: Int = 10
    $search: String
  ) {
    facilityDocumentList(
      filter: $filter
      sortBy: $sort
      pagination: { page: $page, itemsAmount: $itemsAmount }
      searchBy: $search
    ) {
      hits {
        documentId
        documentTypeCode
        documentVersion
        documentDescription
        documentLink
        documentTitle
        documentExpiration
        documentReminder1
        documentReminder2
        facilityIds
        facilityTitles
        fileId
        fileName
        fileMimeType
        documentFileURL
      }
      currentPage
      itemsCount
      pageCount
    }
  }
`;

export const deviceDocumentList = gql`
  query deviceDocumentList(
    $filter: DeviceDocumentFilterInput!
    $sort: [DeviceDocumentSortInput!]
    $page: Int!
    $itemsAmount: Int = 10
    $search: String
  ) {
    deviceDocumentList(
      filter: $filter
      sortBy: $sort
      pagination: { page: $page, itemsAmount: $itemsAmount }
      searchBy: $search
    ) {
      hits {
        deviceDocumentId
        deviceId
        deviceAssetId
        deviceCategory
        deviceCategoryId
        deviceMake
        deviceMakeId
        deviceModel
        deviceModelId
        deviceFacilityTitle
        documentId
        documentVersion
        documentDescription
        documentLink
        documentTitle
        documentExpiration
        documentReminder1
        documentReminder2
        documentTypeCode
        facilityIds
        facilityTitles
        fileId
        fileName
        fileMimeType
        documentFileURL
      }
      currentPage
      itemsCount
      pageCount
    }
  }
`;

export const getFacilityDocumentTypesAndCounts = gql`
  query getFacilityDocumentTypesAndCounts($facilityIds: [Int!], $archived: Boolean!) {
    getFacilityDocumentTypesAndCounts(facilityIds: $facilityIds, archived: $archived) {
      data {
        code
        count
        category
        id
      }
    }
  }
`;

export const getDeviceDocumentTypesAndCounts = gql`
  query getDeviceDocumentTypesAndCounts($facilityIds: [Int!], $archived: Boolean!) {
    getDeviceDocumentTypesAndCounts(facilityIds: $facilityIds, archived: $archived) {
      data {
        code
        count
        category
        id
      }
    }
  }
`;

export const getUserDocumentTypesAndCounts = gql`
  query getUserDocumentTypesAndCounts($archived: Boolean!) {
    getUserDocumentTypesAndCounts(archived: $archived) {
      data {
        code
        count
        category
        id
      }
    }
  }
`;

export const updateArchivedStatus = gql`
  mutation updateArchivedStatus($documentId: Int!, $archived: Boolean!) {
    updateArchivedStatus(data: { documentId: $documentId, archived: $archived }) {
      updated
      failToUpdateReason
    }
  }
`;

export const getDocumentsForReview = gql`
  query getDocumentsForReview($facilityIds: [Int]) {
    getDocumentsForReview(facilityIds: $facilityIds) {
      device
      facility
      personal
    }
  }
`;
