import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const initialize = (userInfo: any) => {
  if (!import.meta.env.VITE_SENTRY_DSN) return;

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_ENV,
    initialScope: (scope) => {
      scope.setUser({ id: userInfo.userId });
      return scope;
    },
    ignoreErrors: [
      // ignore final-form error
      "TypeError: Cannot read properties of undefined (reading 'active')",
      "TypeError: Cannot read property 'active' of undefined",
      'TypeError: t is undefined',
      "TypeError: undefined is not an object (evaluating 't.active')",
    ],
  });
};
