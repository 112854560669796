import React from 'react';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import NoResultsPlaceholder from '@uptime/shared/components/NoResultsPlaceholder';
import useIsMobileView from '../../hooks/useIsMobileView';

import { Outline, HeadCell, Pagination, Sort } from './types';
import useStyles from './styles';
import DesktopList from './partials/DesktopList';
import MobileList from './partials/MobileList';

export const ORDER_DESC = 'desc';
export const ORDER_ASC = 'asc';
export const ROWS_PER_PAGE = 10;

interface Props {
  selectable?: boolean;
  onSort?: Function;
  headCells: HeadCell[];
  data: any[];
  onChangePage?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  pagination?: Pagination | null;
  sort?: Sort;
  isOnlyDesktopView?: boolean;
  isAscSortingFirst?: boolean;
  isSynchronousMode?: boolean;
  fetchEveryPageSeparately?: boolean;
  tableTitle?: string;
  customClass?: string;
  rowStyle?: any;
  isCompact?: boolean;
  outline?: Outline;
}

const List = (props: Props) => {
  const defaultOrder = props.isAscSortingFirst ? ORDER_ASC : ORDER_DESC;
  const {
    selectable,
    onSort = () => {},
    headCells,
    data,
    onChangePage = () => {},
    pagination,
    sort: { order = defaultOrder, column } = {},
    isOnlyDesktopView,
    isAscSortingFirst,
    isSynchronousMode = false,
    tableTitle,
    customClass,
    rowStyle,
    fetchEveryPageSeparately = false,
    isCompact = false,
    outline,
  } = props;

  const classes = useStyles();
  const borderClass = outline ? classes[outline] : '';

  let currentPage = 1,
    itemsCount = 0;

  if (pagination) {
    currentPage = isSynchronousMode ? pagination?.currentPage : pagination?.currentPage - 1;
    if (fetchEveryPageSeparately) {
      currentPage = pagination?.currentPage;
    }
    itemsCount = pagination?.itemsCount;
  }

  const handleRequestSort = (property: any) => () => {
    const isSwitchSorting = column === property && order === defaultOrder;
    const descOrder = isSwitchSorting ? ORDER_ASC : ORDER_DESC;
    const ascOrder = isSwitchSorting ? ORDER_DESC : ORDER_ASC;

    onSort({
      column: property,
      order: isAscSortingFirst ? ascOrder : descOrder,
    });
  };

  const tableData = isSynchronousMode
    ? data.slice(currentPage * ROWS_PER_PAGE, currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE)
    : data;

  const isMobileView = useIsMobileView();

  if (isMobileView === undefined) return null;

  if (!data || data.length === 0) {
    return (
      <Box mt={isCompact ? 0 : 20}>
        <NoResultsPlaceholder />
      </Box>
    );
  }

  return (
    <>
      <div id="first-in-List" className={`${classes.root} ${borderClass} ${customClass}`}>
        {isMobileView && !isOnlyDesktopView ? (
          <MobileList
            headCells={headCells}
            bodyCells={tableData}
            order={order}
            orderBy={column}
            onRequestSort={handleRequestSort}
          />
        ) : (
          <DesktopList
            headCells={headCells}
            bodyCells={tableData}
            order={order}
            orderBy={column}
            onRequestSort={handleRequestSort}
            selectable={selectable}
            rowStyle={rowStyle}
            tableTitle={tableTitle}
            itemsCount={itemsCount}
            customClass={customClass}
          />
        )}
      </div>
      {pagination && tableData.length && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={pagination.itemsCount}
          rowsPerPage={ROWS_PER_PAGE}
          page={currentPage}
          onPageChange={onChangePage}
        />
      )}
    </>
  );
};

export default List;
