import { useQuery } from '@apollo/client';

import { getUserDashboards } from '@uptime/shared/graphql/users';

import { userClient } from 'apolloClientConfiguration';
import usePermissions from 'shared/hooks/usePermissions';

export default () => {
  const { hasAnyExploDashboardPermission } = usePermissions();

  const { data } = useQuery(getUserDashboards, {
    client: userClient,
    skip: !hasAnyExploDashboardPermission,
  });

  return data?.getUserDashboards;
};
