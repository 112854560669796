import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { filesClient } from 'apolloClientConfiguration';
import { getDocumentsForReview } from 'graphql/documents';
import usePermissions from 'shared/hooks/usePermissions';

interface Props {
  variables?: {
    facilityIds: string[];
  };
  skip: boolean;
}

interface Response {
  getDocumentsForReview: {
    device: number;
    facility: number;
    personal: number;
  };
}

const useDocumentsCount = ({ skip, variables }: Props) => {
  const { data } = useQuery<Response>(getDocumentsForReview, {
    client: filesClient,
    skip,
    variables,
  });

  const {
    docHubFacilityManagementPermission,
    docHubFacilityListPermission,
    docHubDeviceManagementPermission,
    docHubDeviceListPermission,
    docHubPersonalManagementPermission,
  } = usePermissions();

  const facilityDocsCount = useMemo<number>(() => {
    if (docHubFacilityManagementPermission || docHubFacilityListPermission) {
      return data?.getDocumentsForReview.facility || 0;
    }

    return 0;
  }, [data, docHubFacilityManagementPermission, docHubFacilityListPermission]);

  const deviceDocsCount = useMemo<number>(() => {
    if (docHubDeviceManagementPermission || docHubDeviceListPermission) {
      return data?.getDocumentsForReview.device || 0;
    }

    return 0;
  }, [data, docHubDeviceManagementPermission, docHubDeviceListPermission]);

  const personalDocsCount = useMemo<number>(() => {
    if (docHubPersonalManagementPermission) {
      return data?.getDocumentsForReview.personal || 0;
    }

    return 0;
  }, [data, docHubPersonalManagementPermission]);

  const documentsCount = useMemo<number>(() => {
    return facilityDocsCount + deviceDocsCount + personalDocsCount;
  }, [facilityDocsCount, deviceDocsCount, personalDocsCount]);

  return {
    documentsCount,
  };
};

export default useDocumentsCount;
